import { useContext, useState } from 'react';
import { ContextType } from '@ui/components/context';
import Button from 'react-bootstrap/Button';
import { getAction } from './utils';
import { AlertType } from '@shares/alert-box';

import { ConnectStatus, trackConnectionBtn } from './tracking';

interface ConnectButtonProps {
  type: 'facebook' | 'google';
  toggleAlertBox: (errorType: AlertType, massage?: string) => void;
}

const ConnectButton = (props: ConnectButtonProps) => {
  const context = useContext(ContextType);
  const [isLoading, setLoading] = useState(false);
  const action = getAction(props.type);
  const { consumer } = context.auth;
  const isConnected = consumer[`${props.type}Profile`] && consumer[`${props.type}Profile`].id != '';

  const onClick = async () => {
    setLoading(true);
    if (isConnected) {
      await action.disconnect();
      await context.refreshAuth();
      const type = props.type.charAt(0).toUpperCase() + props.type.slice(1);
      trackConnectionBtn(props.type, ConnectStatus.Disconnect);
      props.toggleAlertBox(AlertType.Success, `ยกเลิกการเชื่อมต่อ ${type} สำเร็จแล้ว`);
      setLoading(false);
    } else {
      const state = {
        type: 'connect',
        id: consumer.id,
        redirect_url: window.location.origin + window.location.pathname,
        access_token: context.accessToken
      };
      trackConnectionBtn(props.type, ConnectStatus.Connect);
      const link = action.getLink(state);
      window.location.replace(link);
    }
  };
  return (
    <div
      style={{
        padding: 16,
        margin: '4px 0px',
        borderRadius: 4,
        border: '1px solid #e2eaf3'
      }}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div style={{ flex: 4 }}>{action.renderDisplay(consumer)}</div>
        <div style={{ flex: 4, display: 'flex', justifyContent: 'flex-end' }}>
          {isLoading ? (
            <div style={{ width: 50, height: 40 }} className="fetching" />
          ) : (
            <Button
              style={{ height: 40, fontSize: 12 }}
              onClick={onClick}
              variant={isConnected ? 'outline-danger' : 'outline-primary'}
              size="sm"
            >
              {isConnected ? 'ยกเลิกการเชื่อมต่อ' : 'เชื่อมต่อ'}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ConnectButton;
