import { useEffect } from 'react';
import { AlertType } from '@shares/alert-box';
import ConnectButton from './connect-button';
import { readCookie, deleteCookie } from '@utils';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export interface ProfileSocialNetworksProps {
  toggleAlertBox: (errorType: AlertType, massage?: string) => void;
}

const getErrorMessage = (err: string) => {
  switch (err) {
    case 'google_already_connected':
      return 'บัญชี Google นี้ได้ถูกเชื่อมต่อกับบัญชีอื่นแล้ว';
    case 'facebook_already_connected':
      return 'บัญชี Facebook นี้มีการเชื่อมต่อกับบัญชีผู้ใช้อื่นแล้ว';
    case 'facebook_already_registered':
      return 'อีเมล Facebook นี้มีบัญชีสมาชิกอยู่แล้ว';
    case 'google_already_registered':
      return 'อีเมล Google นี้มีบัญชีสมาชิกอยู่แล้ว ';
    default:
      return 'ระบบเกิดข้อผิดพลาด โปรดลองใหม่อีกครั้ง';
  }
};

const getSuccessMessage = (msg: string) => {
  switch (msg) {
    case 'facebook_connected':
      return 'เชื่อมต่อ Facebook สำเร็จแล้ว';
    case 'google_connected':
      return 'เชื่อมต่อ Google สำเร็จแล้ว';
    default:
      return '';
  }
};

const UserProfileSocialNetworks = (props: ProfileSocialNetworksProps) => {
  useEffect(() => {
    const messages = {
      error: readCookie('_flashError'),
      success: readCookie('_flashSuccess')
    };

    if (messages.error) {
      props.toggleAlertBox(AlertType.Error, getErrorMessage(messages.error));
      deleteCookie('_flashError');
    }

    if (messages.success) {
      props.toggleAlertBox(AlertType.Success, getSuccessMessage(messages.success));
      deleteCookie('_flashSuccess');
    }

    deleteCookie('_event');
    deleteCookie('_platform');
  }, []);
  return (
    <div className="socialnetworks  p-3 p-lg-0">
      <h2>การเชื่อมต่อ</h2>
      <Row>
        <Col lg={6}>
          <ConnectButton toggleAlertBox={props.toggleAlertBox} type="facebook" />
        </Col>
        <Col lg={6}>
          <ConnectButton toggleAlertBox={props.toggleAlertBox} type="google" />
        </Col>
      </Row>
    </div>
  );
};

export default UserProfileSocialNetworks;
